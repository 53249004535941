<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de usuários</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Usuario.cusId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field v-model="Usuario.cusNome" label="Nome" variant="outlined"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="Usuario.cusEmail" label="E-mail" variant="outlined"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusSenha" :type="senhaVisivel ? 'text' : 'password'"
                                        label="Senha" variant="outlined"
                                        :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="toggleSenhaVisivel"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusTelefone" label="Telefone" variant="outlined"
                                        v-mask="'(##) ####-#####'" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusWhatsapp" label="Whatsapp" variant="outlined"
                                        v-mask="'(##) ####-#####'" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select v-model="Usuario.cusAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="cusAtivo" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3" v-if="TipoUsuario != ''">
                                    <v-select v-model="Usuario.tusId" label="Tipo Usuário" :items="TipoUsuario" @update:modelValue="CarregaLista(Usuario.tusId)"
                                        item-title="tusNome" item-value="tusId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-file-input v-model="selectedFile" label="Imagem" variant="outlined"
                                        prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Usuario.cusUrlImagem !== '' && Usuario.cusUrlImagem !== null">
                                    <img :src="$imgURL + Usuario.cusUrlImagem" style="width: 100px" />
                                    <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                        <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="6" style="background-color:beige;" v-if="ListImobiliarias!=''" v-show="false">
                                            <v-combobox v-model="ImobSelected" :label="lblcli" :items="ListImobiliarias"
                                            searchable
                                            clearable
                                            auto-select-first="exact"
                                            
                                              item-title="cliNomeEmpresaConcat" item-value="cliId" variant="outlined">
                                        </v-combobox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" @click="Limpar()" class="btn btn-danger" style="margin-right: 15px;color:#ffffff;">
                                    Cancelar
                                </button>
                                <button v-if="Usuario.cusId != 0" type="button" class="btn btn-warning" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Usuarios" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cusUrlImagem="item">
                            <img v-if="item.cusUrlImagem!='' && item.cusUrlImagem!=null" :src="$imgURL + item.cusUrlImagem" style="width: 40px;height:auto;border-radius:50px" />
                            <img v-else src="/images/userNo.png" style="width: 40px;height:auto;border-radius:50px" />
                        </template>
                        <template #item-cusAtivo="item">
                            <span>{{ item.cusAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cusDtCadastro="item">
                            <span>{{ formattedDate(item.cusDtCadastro) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'


export default {
    name: 'CadastroUsuariosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        User:{},
        Usuario: {
            cusId: 0,
            cusNome: '',
            cusEmail: '',
            cusTelefone: '',
            cusWhatsapp: '',
            cusSenha: '',
            cusDtCadastro: null,
            tusId: '',
            cusAtivo: '',
            cusUrlImagem: '',
            cliId:''
        },
        Usuarios: [],
        TipoUsuario: [],
        StatusAtivo: [{ nome: "Sim", cusAtivo: true }, { nome: "Não", cusAtivo: false }],
        headers: [
            { text: "Id", value: "cusId", sortable: true, },
            { text: "Imagem", value: "cusUrlImagem", },
            { text: "Nome", value: "cusNome", sortable: true, },
            { text: "Email", value: "cusEmail", sortable: true, },
            { text: "Whatsapp", value: "cusWhatsapp", },
            { text: "Data Cadastro", value: "cusDtCadastro",  },
            { text: "Tipo", value: "admTipoUsuario.tusNome", sortable: true, },
            { text: "Ativo", value: "cusAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        searchValue: '',
        itemsSelected: null,
        senhaVisivel: false,
        selectedFile: null,
        ListImobiliarias:[],
        ImobSelected:[],
        showCli: false,
        lblcli:'',
        ListImobiliariasGeral:[],
        TelasImobAcesso:[
            {
                "cdtId": 1,
                "tusId": 0,
                "cusTipo": 1
            },
                {
                "cdtId": 13,
                "tusId": 0,
                "cusTipo": 1
            },    
            {
                "cdtId": 34,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 17,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 28,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 32,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 35,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 36,
                "tusId": 0,
                "cusTipo": 1
            },
        ],
        TelasParcAcesso: [
        {
            "cdtId": 1,
            "tusId": 0,
            "cusTipo": 1
        }, 
        {
            "cdtId": 34,
            "tusId": 0,
            "cusTipo": 1
        },
        {
            "cdtId": 37,
            "tusId": 0,
            "cusTipo": 1
        },
        {
            "cdtId": 36,
            "tusId": 0,
            "cusTipo": 1
        },
    ],
    }),
    methods: {
        formattedDate(value) {
            return this.$moment(value).format('DD/MM/YYYY')
        },
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
        Limpar() {
            this.Usuario.cusId = 0;
            this.Usuario.cusNome = '';
            this.Usuario.cusEmail = '';
            this.Usuario.cusTelefone = '';
            this.Usuario.cusWhatsapp = '';
            this.Usuario.cusSenha = '';
            this.Usuario.cusDtCadastro = '';
            this.Usuario.tusId = '';
            this.Usuario.cusAtivo = '';
            this.Usuario.cusUrlImagem = '';
            this.selectedFile = null;
            this.showCli = false;
            this.Get();
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
            const valida = this.Usuarios.filter(x=> x.cusEmail.toLowerCase() == this.Usuario.cusEmail.toLowerCase() && x.cusId != this.Usuario.cusId && x.admTipoUsuario.tusId == this.Usuario.tusId)
          // console.log(valida)
          // console.log(this.Usuarios)
           if (valida.length > 0){
            this.$mensagemAviso("E-mail já cadastrado.")
           }
            else if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.Usuario.tusId == '' || this.Usuario.tusId == 0) {
                this.$mensagemAviso("Tipo Usuário Obrigatório.")
            }
            else if (this.Usuario.cusAtivo === ''){
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if (this.Usuario.tusId == 3 && this.ImobSelected == ''){
                this.$mensagemAviso("Selecione Imobiliária.")
            }
            else {
                this.Usuario.admTipoUsuario = { tusId: this.Usuario.tusId };
                this.Usuario.cliId = parseInt(this.User.cliId);
                
                this.Usuario.cusDtCadastro = moment();
                if (this.Usuario.cusAtivo === "" || this.Usuario.cusAtivo === null){
                    this.Usuario.cusAtivo = true;
                }
                if (this.selectedFile != '' && this.selectedFile != null) {
                   // console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        await axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                            .then(response => {
                                if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.CarregaPermissao(response.data,this.Usuario.tusId);
                                    this.EnviaEmailUser(this.Usuario)
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Usuario.cusUrlImagem = "";
                    this.Usuario.cliId = parseInt(this.User.cliId);

                    axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaPermissao(response.data,this.Usuario.tusId);
                                this.EnviaEmailUser(this.Usuario)
                            }
                        });
                }
            }
        },
        EnviaEmailUser(userDados) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                    .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 6);
                            let _dadosEmail = {
                                            nomeDe: this.DadosGarantidora[0].itiNome,
                                            nomePara: userDados.cusNome,
                                            assunto: 'Cadastro Sistema Aqui Garante Imobiliárias',
                                            destinatario:  userDados.cusEmail,
                                            emailResposta: this.DadosGarantidora[0].itiReferencia,
                                            textoEmail: this.templateEmail[0].mpdTexto.replace('[Email]', userDados.cusEmail).replace('[SenhaInicial]', userDados.cusSenha).replace('[LinkSistema]', 'https://aquigarante.net.br').replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                            .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                            nomeAnexo: '',
                                            anexo: '',
                                            tipoAnexo: ''
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail)
                                .then(response => {
                                    if (response.data == 1){
                                    this.$mensagemSucesso("Enviado e-mail de acesso ao usuário! ");
                                    this.Limpar();
                                    this.tab = 'dados';        
                                    }
                                });

            });
        },
        async Put() {
            const valida = this.Usuarios.filter(x=> x.cusEmail.toLowerCase() == this.Usuario.cusEmail.toLowerCase() && x.cusId != this.Usuario.cusId && x.admTipoUsuario.tusId == this.Usuario.tusId)

            if (valida.length > 0){
            this.$mensagemAviso("E-mail já cadastrado.")
            }
           else if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.Usuario.tusId == '' || this.Usuario.tusId == 0) {
                this.$mensagemAviso("Tipo Usuário Obrigatório.")
            }
            else if (this.Usuario.cusAtivo === ''){
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if (this.Usuario.tusId == 3 && this.ImobSelected == ''){
                this.$mensagemAviso("Selecione Imobiliária.")
            }
            else if (this.Usuario.tusId == 4 && this.ImobSelected == ''){
                this.$mensagemAviso("Selecione Parceiro.")
            }
            else {
                this.Usuario.admTipoUsuario = { tusId: this.Usuario.tusId };
                this.Usuario.cliId = parseInt(this.User.cliId);

                if (this.Usuario.cusAtivo === "" || this.Usuario.cusAtivo === null){
                    this.Usuario.cusAtivo = true;
                }
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    if (this.Usuario.cusUrlImagem!= ""){
                        this.Usuario.cusUrlImagem = "0";
                    }
                    this.Usuario.cliId = parseInt(this.User.cliId);

                    axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }
            }
        },
        EditGrid(item) {
            // Lógica para editar o item   
            this.Usuario = item;
            this.Usuario.tusId = item.admTipoUsuario.tusId
            this.CarregaLista(item.admTipoUsuario.tusId);
            this.ImobSelected = this.ListImobiliariasGeral.filter(x=> x.cliId == item.cliId)[0];
            this.$scrollToTop();
        },
        DeleteGrid(item) {
            if (this.Usuarios.length > 1){    
                
            axios.get('/Process/PedPedidos/GetByPedUserUsuarioId/' + this.User.id)
                        .then(response => {
                            this.PedidoUser = response.data;
                            // console.log('this.PedidoUser')
                            // console.log(this.PedidoUser)
                            if (this.PedidoUser != null && this.PedidoUser != ''){

                                this.$mensagemAviso("Usuário já possui contratações, utilize opção Ativo não para bloquear usuario.") 
                            }
                            else{
                                
                                this.$mensagemConfirma('Tem certeza que deseja excluir?')
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        // Lógica para excluir o item
                                        const param1 = item.cusId;
                                        axios.delete(`/Process/UsrCadastroUsuarios/Delete/${param1}`)
                                            .then(response => {
                                                if (response.status == 200) {
                                                    this.$mensagemAvisoSucesso("Usuário Excluído.")
                                                    this.Get();
                                                    this.Limpar();
                                                }
                                            });
                                    }
                                });
                                   }                           
                        })
                        .catch(error => {
                            console.error(error);
                        });
            }
            else{
                this.$mensagemAviso("Não é possível excluir usuário.") 
            }
        },
        DeleteImagem(){
            this.Usuario.cusUrlImagem = "";
            /*this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        axios.post(`/Process/UploadImg/delete/${value}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Imagem Excluida.")
                                    
                                }
                            });
                    }
                });*/
        },
       async CarregaLista(value){
        this.ImobSelected = [];
           if (value == 3 || value == 4){
                            if (value == 3) {
                                this.ListImobiliarias = this.ListImobiliariasGeral.filter(x=> x.cliTipoCli == 67);  
                                this.ListImobiliarias.forEach(item => {
                                // Verifica se cliCampoExtra1 é diferente de null e não é uma string vazia
                                const cliCampoExtra1 = item.cliCampoExtra1 ? ` - ${item.cliCampoExtra1}` : '';
                                // Concatena cliNomeEmpresa e cliCampoExtra1 para formar a nova propriedade
                                item.cliNomeEmpresaConcat = `${item.cliNomeEmpresa}${cliCampoExtra1}`;
                                });
                                this.lblcli = "Imobiliária";
                                //.log('aqui')
                                //console.log( this.ListImobiliarias)
                                if (this.ListImobiliarias.length == 1){
                                    this.ImobSelected = this.ListImobiliarias[0];
                                }
                            }
                            else{
                                this.ListImobiliarias = this.ListImobiliariasGeral.filter(x=> x.cliTipoCli == 70);  
                                this.ListImobiliarias.forEach(item => {
                                // Verifica se cliCampoExtra1 é diferente de null e não é uma string vazia
                                const cliCampoExtra1 = item.cliCampoExtra1 ? ` - ${item.cliCampoExtra1}` : '';
                                // Concatena cliNomeEmpresa e cliCampoExtra1 para formar a nova propriedade
                                item.cliNomeEmpresaConcat = `${item.cliNomeEmpresa}${cliCampoExtra1}`;
                                });
                                this.lblcli = "Parceiro";
                            } 

                this.showCli = true;
           }
           else{
              this.showCli = false;

           }
        },
        CarregaPermissao(value,tipo){
          if (tipo == 3){
            this.TelasImobAcesso.map(element => {
                                const Permissoes2 = {
                                    cusId: value,
                                    cdtId: parseInt(element.cdtId),
                                    cusTipo: parseInt(element.cusTipo)
                                };
                                return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                                    .then(response => {
                                        if (response.data > 0) {
                                            return null;
                                        }
                                    });
                                });
          }
        },
        Get() {
            const valorArmazenado = localStorage.getItem('_useravImob');
                if (valorArmazenado != '') {
                 this.User = JSON.parse(valorArmazenado);
                // console.log('this.User')
                 //console.log(this.User)
                }

                    axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                        .then(response => {
                            this.Usuarios = response.data.filter(x=> x.cusId == this.User.id);
                        });

                        axios.get(`/Process/CliClientes/GetAll`)
                        .then(response => {
                           this.ListImobiliariasGeral = response.data.filter(x=> x.cliId == this.User.cliId);
                           this.CarregaLista(this.User.tipo);
                           //console.log('this.ListImobiliariasGeral')
                           //console.log(this.ListImobiliariasGeral)
                        });
        }
    },
    created() {
        this.Get();

        axios.get(`/Process/AdmTipoUsuario/GetAll`)
            .then(response => {
                this.TipoUsuario = response.data.filter(x => x.tusVisivel && x.tusId == 3).sort((a, b) => {
                    const nomeA = a.tusNome || "";
                    const nomeB = b.tusNome || "";
                    return nomeA.localeCompare(nomeB);
                });
            });

    },
}
</script>

<style></style>
